import React, {Component} from "react"
import {navigate} from "gatsby"

import LogoHeader from "../components/logo-header"
import Footer from "../components/footer"
import {Button as PrimaryButton} from "../components/base/button"
import lottie from "lottie-web"
import checked_done from "../animations/checked-done.json"
import SEO from "../layouts/seo"

import "./verification-success.scss"

export default class verificationSuccess extends Component {
  state = {}
  constructor(props) {
    super(props)
    this.animationContainer = React.createRef()
    this.handleLogin = this.handleLogin.bind(this)
  }

  componentDidMount() {
    this.anim = lottie.loadAnimation({
      container: this.animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: checked_done,
    })

    setTimeout(this.handleLogin, 5000)
  }

  handleLogin = () => {
    navigate("/signin", {
      state: this.props?.location?.state,
    })
  }

  render() {
    return (
      <div className="verify-success-container">
        <SEO title="Verified Failed" />
        <LogoHeader />
        <div className="verify-success-content">
          <div className="checked-done-container">
            <div className="checked-done" ref={this.animationContainer}></div>
          </div>
          <div className="header">Verified</div>
          <div className="subtitle">
            Will redirect to sign-in page shortly or click here
          </div>
          <PrimaryButton onClick={this.handleLogin} width={480}>
            Sign In
          </PrimaryButton>
        </div>
        <Footer />
      </div>
    )
  }
}
